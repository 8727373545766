import React, { useState, useEffect } from "react";
import coesc from "../../../assets/images/coesc.jpeg";
import duton from "../../../assets/images/duton.jpg";
import "../../../assets/css/invoice.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import QRCode from "qrcode.react";
import numberToWords from "number-to-words";
import InvoiceNumberComponent from "../Format";
import { ToWords } from "to-words";
import sign from "../../../assets/images/sign.jpeg";

const tableStyle = {
  borderCollapse: "collapse",
  width: "100%",
  marginRight: "auto",
};

const thTdStyle = {
  border: "1px solid black",
  padding: "5px",
  textAlign: "left",
};

const thStyle = {
  border: "1px solid black",
  padding: "5px",
  textAlign: "left",
};

const InvoiceDetailsModal = () => {
  const [url, setUrl] = useState("");
  const toWords = new ToWords();

  useEffect(() => {
    const currentUrl = window.location.href;
    setUrl(currentUrl);
  }, []);

  const invoiceId = useParams();
  const userToken = localStorage.getItem("userToken");
  const [invoice, setInvoice] = useState({});
  const [owner, setOwner] = useState({});
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    loadInvoice();
    // window.print();
  }, []);

  const loadInvoice = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}invoice/${invoiceId.invoiceId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setInvoice(response.data.invoice);
        setCustomer(response.data.customerDetails);
        setOwner(response.data.createdBy);
        console.log(invoice);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadInvoice();
  }, []);

  return (
    <div className="fonts parent inv-box">
      <div className="Table-container">
        <div className="Table-container-one">
          <h6 id="invoicetype">{invoice?.invType?.toUpperCase()} INVOICE</h6>

          <div className="Table-container-two topCon invoice-logo">
            <p>
              <img src={invoice?.logoURL} />
            </p>

            <p id="comdetails">
              <strong> {owner?.orgName}</strong>
              <p>
                {owner?.address}
                <br /> {owner?.city},{owner?.state} <br />
                <br />
              </p>
            </p>
          </div>

          <div className="Table-container-three">
            <div className="">
              {owner?.gstNo && (
                <h6>
                  GST NO : <span id="bold">{owner?.gstNo} </span>
                </h6>
              )}
              {owner?.pan && (
                <h6>
                  PAN NO : <span id="bold">{owner?.pan} </span>
                </h6>
              )}
            </div>
            <div className="">
              {owner?.cinNumber && (
                <h6>
                  CIN/LLPIN : <span id="bold">{owner?.cinNumber} </span>
                </h6>
              )}
              {owner?.state && (
                <h6>
                  State Name : <span id="bold">{owner?.state} </span>
                </h6>
              )}
            </div>
            <div className="">
              {owner?.iecNumber && (
                <h6>
                  IEC No : <span id="bold">{owner?.iecNumber} </span>
                </h6>
              )}
              {owner?.stateCode && (
                <h6>
                  State Code : <span id="bold">{owner?.stateCode}</span>
                </h6>
              )}
            </div>
          </div>

          <div className="Table-container-four">
            <div className="container-four-left">
              <table id="table3">
                <tbody>
                  <tr>
                    <th
                      style={{
                        height: "auto",
                        width: "440px",
                      }}
                    >
                      <p id="font">Buyer</p>
                      <p>
                        <strong id="org">
                          {invoice?.orgName + ","}
                          <br />
                        </strong>
                        {invoice?.customAddress}
                      </p>

                      <div className="otherDetails">
                        {invoice?.shipAddress && (
                          <p>
                            Shipping Address:{" "}
                            <span id="bold"> {invoice?.shipAddress}</span>
                          </p>
                        )}

                        <p>
                          Mobile No:{" "}
                          <span id="bold">{invoice?.vendorMobile}</span>
                        </p>
                        {customer?.gstNo != "" && (
                          <p>
                            GST No: <span id="bold">{customer?.gstNo }</span>
                          </p>
                        )}
                        {invoice?.vendorPAN != "" && (
                          <p>
                            PAN No : <span id="bold">{invoice?.vendorPAN}</span>
                          </p>
                        )}
                        {invoice?.vendorCity != "" && (
                          <p>
                            City: <span id="bold">{invoice?.vendorCity}</span>
                          </p>
                        )}
                        {invoice?.vendorState != "" && (
                          <p>
                            State: <span id="bold">{invoice?.vendorState}</span>
                          </p>
                        )}
                      </div>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
            <img src={invoice?.logoURL} id="center" />
            <div className="container-four-right">
              <table id="table4">
                <tbody>
                  <tr>
                    <th id="highlight">
                      Invoice No. :{" "}
                      <span id="highlighttd">
                        <InvoiceNumberComponent
                          page={
                            invoice?.invType === "Proforma" ? "P_INV" : "INV"
                          }
                          billNumber={invoice.billNumber}
                          createdDate={invoice.createdDate}
                        />
                      </span>{" "}
                    </th>
                    <th id="highlight">
                      Invoice Date :
                      <span id="highlighttd">
                        {` `}{" "}
                        {moment(invoice?.createdDate).format("DD-MM-YYYY")}
                      </span>
                    </th>
                  </tr>
                  {invoice?.refQuotationNumber != "" && (
                    <tr>
                      <th>
                        Quotation No. :{" "}
                        {/^[A-Za-z]/.test(invoice?.refQuotationNumber) ? (
                          invoice?.refQuotationNumber
                        ) : (
                          <InvoiceNumberComponent
                            page={"QT"}
                            billNumber={invoice?.refQuotationNumber}
                          />
                        )}{" "}
                      </th>
                      <th>
                        Quotation Date :{" "}
                        {moment(invoice.refQuotationDate).format("DD/MM/YYYY")}
                      </th>
                    </tr>
                  )}{" "}
                  {invoice?.refPONumber != "" && (
                    <tr>
                      <th>
                        PO No : <span id="bold">{invoice?.refPONumber} </span>{" "}
                      </th>

                      {/*<th>Reference No. :  <span id="bold"></span> </th>*/}
                    </tr>
                  )}
                  {invoice?.transporterName && (
                    <>
                      <tr>
                        <th>
                          {" "}
                          Transporter Name : <br />
                          <span id="bold"> {invoice?.transporterName}</span>
                        </th>
                        {invoice?.taxInvoiceNumber && (
                          <th>
                            {" "}
                            Tax Invoice No. :{" "}
                            <span id="bold"> {invoice?.taxInvoiceNumber}</span>
                          </th>
                        )}
                      </tr>

                      <tr>
                        {invoice?.transporterGST && (
                          <th>
                            Transporter GST No :
                            <span id="bold"> {invoice?.transporterGST}</span>
                          </th>
                        )}
                        <th>
                          Challan No : <span id="bold"> 3574 </span>
                        </th>
                      </tr>
                    </>
                  )}
                  <tr>
                    {invoice?.motorNo && (
                      <th>
                        Motor Vehicle No. : {` `}
                        <span id="bold">{invoice?.motorNo}</span>
                      </th>
                    )}
                    {false && (
                      <th>
                        Mode/Terms of Payment:{" "}
                        <span id="bold">{invoice?.paymentTerms}</span>
                      </th>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="Table-container-five">
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                marginLeft: "",
                border: "none",
              }}
            >
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "59%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "12%" }} />
              </colgroup>
              <thead id="head">
                <tr>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    S.N
                  </th>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    Description of Goods
                  </th>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    HSN
                  </th>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    QTY
                  </th>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    UOM
                  </th>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    Unit Price
                  </th>
                  <th style={{ color: "black", border: "1px solid black" }}>
                    Total Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice?.productDesc?.map((p, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid black" }}>{index + 1}</td>
                    <td
                      style={{
                        fontSize: "18px",
                        fontFamily: "sans-serif",
                        border: "1px solid black",
                      }}
                    >
                      {p.name}
                    </td>

                    <td style={{ border: "1px solid black" }}>{p.hsn}</td>
                    <td style={{ border: "1px solid black" }}>{p.qty}</td>
                    <td style={{ border: "1px solid black" }}>{p.uom}</td>
                    <td style={{ border: "1px solid black" }}>
                      {parseFloat(p.price).toFixed(0)}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {(p.qty * p.price).toFixed(0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="Table-container-six-invoice">
            <div style={{ display: "flex", border: "1px solid black" }}>
              <div
                style={{
                  flex: 3.6,
                  borderRight: "1px solid black",
                  paddingTop: "10px",
                }}
              >
                <h6
                  style={{
                    width: "100%",
                    borderBottom: "none",
                    textAlign: "center",
                    verticalAlign: "center",
                  }}
                >
                  Total Amount
                </h6>
              </div>
              <div
                style={{
                  flex: 3,
                  textAlign: "center",
                  verticalAlign: "center",
                  paddingTop: "10px",
                }}
              >
               <span>
  {(
    (parseInt(invoice?.subAfterDiscount) || 0) + (parseInt(invoice?.discount) || 0)
  ).toFixed(0)}
</span>

              </div>
            </div>
            <table style={tableStyle}>
              <tbody className="invoice-tbl">
                <tr>
                  <th style={thTdStyle}>Discount</th>
                  <th style={thTdStyle}>Taxable Amnt</th>
                  <th style={thTdStyle}>Tax Rate/GST</th>
                  <th style={thTdStyle}>IGST AMT</th>
                  <th style={thTdStyle}>CGST AMT</th>
                  <th style={thTdStyle}>SGST AMT</th>
                  <th style={thTdStyle}>Total Amount</th>
                </tr>
                <tr>
                  <td style={thTdStyle}>{invoice?.discount || 0}%</td>
                  <td style={thTdStyle}>
                    {parseFloat(invoice?.subAfterDiscount).toFixed(0) || 0}
                  </td>
                  <td style={thTdStyle}>{"18"}%</td>
                  <td style={thTdStyle}>{invoice?.igst || 0}</td>
                  <td style={thTdStyle}>
                    {parseFloat(invoice?.cgst).toFixed(0) || 0}
                  </td>
                  <td style={thTdStyle}>
                    {parseFloat(invoice?.sgst).toFixed(0) || 0}
                  </td>
                  <td id="bold" style={thTdStyle}>
                    {parseFloat(invoice?.totalINR).toFixed(0) || 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h6 id="border" style={{ width: "100%" }}>
            Amount Chargeable (in words):{" "}
            <span id="words">
              {isNaN(parseFloat(invoice?.totalINR))
                ? "Invalid amount"
                : toWords.convert(Math.round(parseFloat(invoice?.totalINR)))}
            </span>{" "}
            Rupees only
          </h6>

          <div className="Table-container-seven">
            <table id="table7" style={tableStyle}>
              <tbody>
                {invoice?.eWayBillNo && (
                  <tr>
                    <th style={thStyle}>EWaybill No :{invoice?.eWayBillNo}</th>
                  </tr>
                )}
                {invoice?.transNo != "" && (
                  <tr>
                    <th style={thStyle}>
                      Transaction Details :{invoice?.transNo}/{invoice?.transId}{" "}
                    </th>
                  </tr>
                )}
                {invoice?.remarks && (
                  <tr>
                    <th style={{ ...thStyle, fontWeight: "500" }}>
                      Remarks: {invoice?.remarks}
                    </th>
                  </tr>
                )}
                {
                  <tr style={{ borderRight: "1px solid black" }}>
                    <th
                      style={{
                        ...thStyle,
                        width: "100%",
                        fontWeight: "500",
                        borderRight: "none",
                      }}
                    >
                      <strong>Declaration:</strong>
                      <br />
                      Is reverse Charge Applicable?(Yes/No)-{" "}
                      <strong>{invoice?.revCharge}</strong>
                      <br />
                      Thankyou for your valuable order.
                      <br />
                      All disputes shall be addressed at Bangalore Jurisdiction.
                      <br />
                      For questions concerning this invoice, please contact your
                      project assistant.
                      <br />
                      This is a digitally generated invoice copy and does not
                      require signature.
                      <br />
                    </th>
                    <img
                      src={sign}
                      style={{ height: "100px", margin: "5px" }}
                    />
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <div className="Table-container-eight">
            <table id="table8" style={tableStyle}>
              <tbody>
                <tr id="bank">
                  <th style={thStyle}>
                    <strong>Our Bank Details</strong>
                    <br />
                    {owner?.nameOfBank}
                    <br />
                    A/C No. {owner?.accountNo}
                    <br />
                    Branch : {owner?.bankBranch}
                    <br />
                    RTGS/NEFT/IFSC Code:{owner?.ifsc}
                  </th>
                  <th style={thStyle} id="auth">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>For, {owner?.orgName}</div>
                      <div className="qrCode">
                        <QRCode value={url} />
                      </div>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="bottom-details">
        Crafted with ease using Florosense Billing System Visit{" "}
        <p>Visit bill.florosense.co/ to create truly professional invoices</p>
      </div>
    </div>
  );
};

export default InvoiceDetailsModal;
