import React, { useState, useEffect } from 'react';
import floro from "../../../assets/images/flo.jpeg";
import coesc from "../../../assets/images/coesc.jpeg";
import duton from "../../../assets/images/duton.jpg"
import '../../../assets/css/invoice.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import QRCode from 'qrcode.react';
import numberToWords from 'number-to-words';
import InvoiceNumberComponent from '../Format';
const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  marginRight: 'auto',

};

const thTdStyle = {
  border: '1px solid black',
  padding: '5px',
  textAlign: 'left',
};


const thStyle = {
  border: '1px solid black',
  padding: '5px',
  textAlign: 'left',
};


const ChallanDetails = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const currentUrl = window.location.href;
    setUrl(currentUrl);
  }, []);

  const { challanId } = useParams();
  const userToken = localStorage.getItem("userToken");
  const [invoice, setInvoice] = useState({});
  const [owner, setOwner] = useState({});

  useEffect(() => {
    loadInvoice();
    // window.print();
  }, []);


  const loadInvoice = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}challan/${challanId}`,
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    axios.request(config)
      .then((response) => {

        setInvoice(response.data.Challan);
        setOwner(response.data.createdBy)

      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    loadInvoice();
  }, []);






  return (

    <div className='parent inv-box'>

      <div className="Table-container">
        <div className="Table-container-one">
          

          <div className="Table-container-two topCon invoice-logo" style={{padding:'0px'}}>

            <p style={{border:'none',marginRight:'0px'}}><img src={floro} /></p>

            {/* 
<p id="comdetails"><strong> {owner?.orgName}</strong>
  <p>
    {owner?.address}<br /> {owner?.city},
    {owner?.state} <br /><br />

  </p>
</p> */}
            <h6 id="invoicetype" >{invoice?.invType?.toUpperCase()} DELIVERY CHALLAN</h6>
            <div className='billForm'>
              <div className='billin'>
                <label>Original</label>
                <p>.</p>
              </div>
              <div className='billin'>
                <label>Duplicate</label>
                <p>.</p>
              </div>
              <div className='billin last-child'>
                <label>Triplicate</label>
                <p>.</p>
              </div>
            </div>


          </div>
          <div className="justify-cont">
            <div className='billFormLeft'>
              {owner?.orgName &&
                <p id="challanOrg">
                  {owner?.orgName}
                </p>
              }
              {owner?.address &&
                <p >
                  {owner?.address}
                </p>
              }
              {owner?.city &&
                <p >
                  {owner?.city}
                </p>
              }
              {owner?.gstNo &&
                <p >
                  GST:-{owner?.gstNo}
                </p>
              }
            </div>


          </div>



          <div className="Table-container-four">
            <div className="container-four-left challanTableOne">
              <table id="table4">
                <tbody>
                  <tr>
                    <th>Challan No. : <span id="bold"><InvoiceNumberComponent page={'CH'} billNumber={invoice.billNumber} createdDate={invoice.createdDate}  /></span> </th>
                    <th>
                      Transport Mode :
                      <span id="bold">
                        {` `}  {invoice?.transMode}</span>
                    </th>
                  </tr>
                  {invoice?.createdDate != "" &&
                    <tr>
                      <th>Date of Challan. : {moment(invoice?.createdDate).format("DD-MM-YYYY")}</th>
                      <th>Vehicle  Number: {invoice?.vehNo}</th>
                    </tr>
                  } {(invoice?.refPONumber != "") &&
                    <tr>

                      <th>PO No : <span id="bold">{invoice?.poNo} </span>  </th>
                      <th>  Date: <span id="bold">{moment(invoice?.updatedDate).format("DD-MM-YYYY")}</span></th>



                    </tr>
                  }
                  <tr>

                    <th>State :<span id="bold">{owner?.state} </span> | Code <span id="bold">{owner?.stateCode}</span>


                    </th>
                    <th>  Place: <span id="bold">{invoice?.place}</span></th>





                  </tr>
                  {invoice?.transporterName &&
                    <>
                      <tr>
                        <th>
                          {" "}
                          Transporter Name :  <br /><span id="bold">  {invoice?.transporterName}</span>

                        </th>
                        {invoice?.taxInvoiceNumber &&
                          <th>
                            {" "}
                            Tax Invoice No. :  <span id="bold">  {invoice?.taxInvoiceNumber}</span>

                          </th>
                        }
                      </tr>

                      <tr>
                        {invoice?.transporterGST &&
                          <th>
                            Transporter GST No :<span id="bold">  {invoice?.transporterGST}</span>

                          </th>
                        }
                        <th>
                          Challan No : <span id="bold"> 3574{" "}</span>

                        </th>
                      </tr>
                    </>
                  }
                  <tr>
                    {invoice?.motorNo &&
                      <th>
                        Motor Vehicle No. : {` `}
                        <span id="bold">
                          {invoice?.motorNo}</span>
                      </th>
                    }
                    {invoice?.paymentTerms &&
                      <th>
                        Mode/Terms of Payment: <span id="bold">{invoice?.paymentTerms}</span>
                      </th>
                    }
                  </tr>

                </tbody>
              </table>
            </div>


          </div>
          <div className="Table-container-four">
            <div className="container-four-left challanTableTwo">
              <p id="receiver">Details of RECEIVER</p>
              <p>Name <span id="bold">: {invoice?.recName}</span></p>
              <p>Address <span id="bold">: {invoice?.recAdd}</span></p>
              <p>Phone <span id="bold">:  {invoice?.recPhone}</span></p>
              <p>GSTIN <span id="bold">:  {invoice?.recGst}</span></p>
              <p> State :<span id="bold">{invoice?.recState} </span> | Code <span id="bold">{invoice?.recStateCode}</span></p>
            </div>
            <img src={floro} id="center" />

          </div>


          <div className="Table-container-five">
            <table style={{ borderCollapse: 'collapse', width: '100%', marginLeft: '', border: "none" }}>
              <colgroup>
                <col style={{ width: '10%' }} />
                <col style={{ width: '65%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '10%' }} />

              </colgroup>

              <thead>
                <tr>
                  <th style={{ color: "black", border: '1px solid black' }}>S. No.</th>
                  <th style={{ color: "black", border: '1px solid black' }}>Description of Goods</th>
                  <th style={{ color: "black", border: '1px solid black' }}>HSN Code</th>
                  <th style={{ color: "black", border: '1px solid black' }}>QTY</th>
                  {/* <th style={{ color: "black", border: '1px solid black' }}>Unit Price</th>
                <th style={{ color: "black", border: '1px solid black' }}>Total Amount</th> */}
                </tr>
              </thead>
              <tbody>

                {invoice?.productDesc?.map((p, index) => (
                  <tr key={index}>
                    <td style={{ fontSize: '18px', fontFamily: 'sans-serif', border: '1px solid black' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black' }}>{p.name}</td>
                    <td style={{ border: '1px solid black' }}>{p.hsn}</td>
                    <td style={{ border: '1px solid black' }}>{p.qty}</td>
                    {/* <td style={{ border: '1px solid black' }}>{p.price}</td>
                  <td style={{ border: '1px solid black' }}>{p.qty * p.price}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>


          </div>



          <div className="Table-container-eight">
            <table id="table8" style={tableStyle}>
              <tbody>
                <tr>
                  <th style={thStyle}>

                    Received By<br />
                    Name : <br />
                    Comment: <br />
                    Date: <br />
                    Signature:
                  </th>


                </tr>
              </tbody>
            </table>
          </div>

          <div className="Table-container-eight">
            <table id="table8" style={tableStyle}>
              <tbody>
                <tr>
                  <th style={thStyle}>

                    Delivered By<br />
                    Name : {invoice?.delName}<br />
                    Comment: {invoice?.delComm}<br />
                    Date:{moment(invoice?.delDate).format("DD-MM-YYYY")}<br />
                    Signature:
                  </th>


                </tr>
              </tbody>
            </table>
          </div>


          <div className="Table-container-eight">
            <table id="table8" style={tableStyle}>
              <tbody>
                <tr>

                  <th style={thStyle} id='auth'>
                    For, {owner?.orgName}<br />
                    <div className='qrCode'>
                      <QRCode value={url} />
                    </div>
                  </th>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div >
    </div >


  );
};

export default ChallanDetails;
